import Footer from "../../components/Footer";
import Logo from "../../components/Logo";
import Separator from "../../components/Separator";
import Paragraph from "../../components/Paragraph";
import FootingContainer from "./FootingContainer";
import lang from "../../config/lang";
import useLocale from "../../hooks/useLocale";
import { Link } from "react-router-dom";

const Footing = () => {
  const locale = useLocale();
  return (
    <Footer>
      <Logo footer />
      <Separator />
      <FootingContainer>
        <div>
          <Paragraph footer>{lang[locale]?.footer?.address}</Paragraph>
        </div>
        <div>
          <Paragraph footer>{lang[locale]?.footer?.phone_email}</Paragraph>
          <div style={{ marginTop: "8px" }}>
            <Link
              style={{ fontSize: "var(--font-size-footnotes)" }}
              to={"/privacy-policy"}
            >
              Privatumo politika
            </Link>
            <br />
            <Link
              style={{ fontSize: "var(--font-size-footnotes)" }}
              to={"/terms-of-service"}
            >
              Naudojimosi sąlygos
            </Link>
          </div>
        </div>
        <div>
          <Paragraph footer>{lang[locale]?.footer?.code_account}</Paragraph>
        </div>
      </FootingContainer>
    </Footer>
  );
};

export default Footing;
