import Heading from "../heading/Heading";
import Footing from "../footing/Footing";
import Section from "../../components/Section";
import "./termsOfService.module.css";

const TermsOfService = () => {
  return (
    <>
      <Heading />
      <Section>
        <h3>Privatumo ir slapukų politika</h3>
        <ol>
          <li>
            Bendrosios sąlygos
            <ol>
              <li>
                Šios Naudojimosi programa sąlygos (toliau — Sąlygos) ir
                Privatumo politika (toliau — Politika) (toliau kartu —
                Taisyklės) yra teisinis susitarimas tarp UAB „Gasefis“,
                veikiančios pagal Lietuvos Respublikos įstatymus, įmonės kodas
                303575354, registracijos adresas J. Franko g. 12-14, Vilnius,
                Lietuva (toliau — Bendrovė), kaip kipos.lt projektų valdymo
                programos (toliau — KIPOS) savininkės ir administratorės, ir
                Jūsų, kaip KIPOS projektų valdymo programos naudotojo (toliau —
                Naudotojas).
              </li>
              <li>
                Taisyklės nustato naudojimąsi KIPOS projektų valdymo programa
                pagrindines sąlygas. Naudodamiesi KIPOS Jūs sutinkate su šiomis
                Sąlygomis ir patvirtinate, kad esate susipažinę su Politika.
                Jeigu su Sąlygomis ar Politika nesutinkate, Jūs negalite
                naudotis KIPOS projektų valdymo programa ar kitomis Bendrovės
                teikiamomis paslaugomis.
              </li>
            </ol>
          </li>
          <li>
            Registracija
            <ol>
              <li>
                Kiekvienas Naudotojas, registruodamasis kipos.lt interneto
                svetainėje, susikurdamas savo paskyrą ir naudodamasis KIPOS
                paslaugomis, patvirtina, kad susipažino su Produkto naudojimo
                tvarka (Taisyklėmis) ir Asmens duomenų tvarkymo politika (toliau
                dar ir – Politika), Taisykles ir Politiką suprato, besąlygiškai
                sutinka su šiais dokumentais ir neatšaukiamai įsipareigoja jų
                laikytis.
              </li>
              <li>
                Naudotojas patvirtina, kad registracijos metu jo pateikti
                duomenys yra tikslūs, teisingi ir išsamūs. Bendrovė neprisiima
                jokios rizikos ar atsakomybės ir yra nuo jos besąlygiškai
                atleidžiama ne tik Naudotojo, bet ir trečiųjų asmenų atžvilgiu,
                jei Naudotojas nurodė neteisingus ar neišsamius duomenis ir (ar)
                jų nepakeitė. Naudotojas prisiima visą atsakomybę už padarinius,
                kilusius dėl pateiktų duomenų klaidingumo ar netikslumo.
              </li>
              <li>
                Norėdamas naudotis KIPOS projektų valdymo programa, Naudotojas
                privalo registruotis kipos.lt interneto svetainėje. Po
                registracijos jis įsipareigoja laikyti savo prisijungimo
                duomenis paslaptyje.
              </li>
              <li>
                Kiekvienas Naudotojas gali pakviesti jungtis prie savo KIPOS
                paskyroje įkeltų projektų savo klientus (toliau - Klientas).
              </li>
              <li>
                Naudotojo pakviestas Klientas, norėdamas naudotis KIPOS projektų
                valdymo programa, privalo registruotis kipos.lt interneto
                svetainėje. Užsiregistravęs Klientas turi ribotą prieigą prie
                KIPOS projektų valdymo programos funkcionalumo. Naudotojas
                prisiima visą atsakomybę už pakviestus Klientus ir jų netinkamą
                naudojimąsi KIPOS programa.
              </li>
              <li>
                Priklausomai nuo pasirinkto plano, Naudotojas gali prijungti į
                KIPOS savitarnoje sukurtą organizaciją kitus savo įmonės
                darbuotojus.
              </li>
              <li>
                Bendrovė pasilieka teisę bet kuriuo metu keisti Naudotojo
                pateiktus duomenis ar ištrinti / apriboti Naudotojo paskyrą, jei
                Naudotojo pateikti duomenys neatitinka galiojančių Lietuvos
                Respublikos įstatymų arba Bendrovė nustato, kad duomenys yra
                netikslūs, netinkami ar kitaip nepageidaujami.
              </li>
            </ol>
          </li>
          <li>
            Paslaugų kaina ir mokėjimo sąlygos
            <ol>
              <li>
                Teikiamų paslaugų planai, jų kaina bei paslaugų apimtis nurodyta
                ir detalizuota čia ir kiekvienos konkrečios mokamos paslaugos
                užsakymo aplinkoje. Naudotojas, norėdamas pasinaudoti
                papildomomis Bendrovės teikiamomis paslaugomis ar išreiškus
                pageidavimą atlikti paslaugų plane nenurodytus užsakomuosius
                darbus, papildomai už šias teikiamas paslaugas ir darbus moka
                Bendrovei papildomą mokestį.
              </li>
              <li>
                Norėdamas naudotis KIPOS projektų valdymo programa, Naudotojas
                turi pasirinkti vieną iš siūlomų planų.
              </li>
              <li>
                Mokamą planą leidžiama pasirinkti tik Naudotojui suvedus
                kontaktinę informaciją ir galiojančios kreditinės kortelės
                duomenis.
              </li>
              <li>
                Mokestis už suteiktas paslaugas skaičiuojamas už einamąjį
                kalendorinį mėnesį (iki mėnesio paskutinės dienos).
              </li>
              <li>
                Mokėjimas nuo Naudotojo kreditinės kortelės inicijuojamas
                kiekvieno mėnesio 1 d. už per praėjusį mėnesį suteiktas
                paslaugas pagal Naudotojo pasirinktą mokėjimo planą. Sėkmingai
                nuskaičius pinigus nuo Naudotojo kortelės, Naudotojas apie tai
                informuojamas el. paštu.
              </li>
              <li>
                Jei mokestis už suteiktas paslaugas sudaro mažiau nei 1,00
                (vienas) Eur, mokėjimas neinicijuojamas.
              </li>
              <li>
                Nuo Naudotojo kreditinės kortelės nuskaityti pinigai atgal
                negrąžinami.
              </li>
              <li>
                Jeigu mokėjimas nepavyksta (pvz., Naudotojo kortelės likučio
                nepakanka padengti paslaugų mokesčiui, baigia galioti Naudotojo
                kortelė, techniniai trikdžiai ir pan.), Naudotojas apie tai
                informuojamas el. paštu ir po 5 dienų mokėjimas pakartotinai
                inicijuojamas.
              </li>
              <li>
                Sąskaita už per praėjusį kalendorinį mėnesį suteiktas paslaugas
                išrašoma ir siunčiama Naudotojui el. paštu kiekvieno mėnesio 1
                d.
              </li>
              <li>
                Bendrovė pasilieka teisę savo nuožiūra neapdoroti mokėjimų ir
                neteikti prieigos prie KIPOS projektų valdymo programos arba
                riboti mokėjimų skaičių ir maksimalią mokėjimo sumą konkrečiam
                asmeniui ar visai KIPOS projektų valdymo programoje esančių
                klientų bazei.
              </li>
              <li>
                Bendrovė pasilieka teisę keisti KIPOS projektų valdymo programos
                naudojimo sąlygas ir kainas.
              </li>
            </ol>
          </li>
          <li>
            Intelektinė nuosavybė
            <ol>
              <li>
                Bendrovė suteikia Naudotojui ribotą, neišskirtinę ir
                neperleidžiamą licenciją naudotis KIPOS projektų valdymo
                programa Bendrovės nustatyta tvarka, sąlygomis ir būdais.
              </li>
              <li>
                Bendrovei priklauso visos turtinės teisės į KIPOS projektų
                valdymo programą, įskaitant, bet neapsiribojant, autorių
                turtines teises į programinį kodą, išimtines teises į išradimus,
                patentus, vartotojo grafinės sąsajos dizainą, tekstus, garsus,
                interaktyvias funkcijas. Nurodytų KIPOS projektų valdymo
                programos elementų apsaugai kartu ir atskirai taikomi teisės
                aktai, apibrėžiantys intelektinės nuosavybės apsaugą. Šių
                elementų naudojimas be Bendrovės sutikimo yra draudžiamas.
              </li>
              <li>
                Bendrovės juridinio asmens pavadinimas, prekių ženklas KIPOS,
                logotipai ir kiti komerciniai identifikatoriai, kuriuos Bendrovė
                naudoja, yra išimtinai bendrovės nuosavybė, nepriklausomai nuo
                to, ar jie yra registruoti, ar ne. Šių ženklų ar pavadinimų
                naudojimas be Bendrovės sutikimo yra draudžiamas.
              </li>
            </ol>
          </li>
          <li>
            Naudotojų teisės ir pareigos
            <ol>
              <li>
                Naudotojas, naudodamasis KIPOS projektų valdymo programa,
                prisiima atsakomybę pagal Taisykles ir kitas galimas taisykles,
                kurios patalpintos Bendrovės internetinėje svetainėje
                www.kipos.lt ir įsipareigoja naudoti KIPOS projektų valdymo
                programą nurodytomis sąlygomis.
              </li>
              <li>
                Naudotojas turi teisę:
                <ol>
                  <li>
                    Pakeisti jam suteiktą prisijungimo vardą, slaptažodį ir el.
                    pašto adresą;
                  </li>
                  <li>
                    Reikalauti savo paskyros blokavimo arba jos atsisakyti;
                  </li>
                  <li>
                    Kreiptis el. paštu info@kipos.lt su reikalavimu nepagrįstai
                    nedelsiant ištrinti su juo susijusius asmens duomenis;
                  </li>
                  <li>
                    Atspausdinti arba išsaugoti elektroniniame formate
                    Naudotojui skirtus dokumentus ir duomenis;
                  </li>
                  <li>
                    Naudoti KIPOS projektų valdymo programą pagal taisykles,
                    nurodytas kainų puslapyje ir bendruosiuose aprašymuose.
                  </li>
                </ol>
              </li>
              <li>
                Naudotojas įsipareigoja:
                <ol>
                  <li>
                    Praradęs savo prisijungimo duomenis arba kai jie yra
                    atskleisti ne dėl jo kaltės, nedelsiant informuoti apie tai
                    Bendrovę el. paštu info@kipos.lt;
                  </li>
                  <li>Reguliariai sekti Sąlygų ir Politikos pakeitimus;</li>
                  <li>
                    Naudoti KIPOS projektų valdymo programą tik tokiu būdu,
                    kuris neprieštarauja galiojantiems įstatymams, įskaitant
                    galiojančius apribojimus dėl intelektinės nuosavybės teisių.
                  </li>
                </ol>
              </li>
              <li>
                KIPOS projektų valdymo programos Naudotojui yra draudžiama:
                <ol>
                  <li>
                    Registracijos proceso metu pateikti tikrovės neatitinkančią
                    informaciją apie save ar neatnaujinti informacijos jai
                    pasikeitus;
                  </li>
                  <li>
                    Be Bendrovės sutikimo adaptuoti KIPOS projektų valdymo
                    programą kitoms platformoms negu ji yra pritaikyta;
                  </li>
                  <li>
                    Bet kokiais būdais techniškai ir kenkėjiškai trikdyti KIPOS
                    projektų valdymo programos darbą;
                  </li>
                  <li>
                    Pažeisti kitų KIPOS projektų valdymo programos naudotojų
                    teises į privatumą ir (arba) kitas teises arba rinkti
                    duomenis ir informaciją apie naudotojus be jų išankstinio
                    sutikimo, nesvarbu kokiomis priemonės tai būtų atliekama;
                  </li>
                  <li>
                    Be Bendrovės sutikimo naudoti KIPOS bet kokiai veiklai, kuri
                    tiesiogiai ar netiesiogiai neatitinka KIPOS projektų valdymo
                    programos paskirčiai;
                  </li>
                  <li>
                    Nepagrįstai apkrauti serverį, įskaitant, bet neapsiribojant,
                    didelės apimties duomenų saugojimą, nepagrįstai didelio
                    skaičiaus operacijų vykdymą;
                  </li>
                  <li>
                    Naudoti KIPOS projektų valdymo programą ne pagal paskirtį
                    arba nenaudoti reikšmingą laiką.
                  </li>
                </ol>
              </li>
              <li>
                Visi aukščiau nurodyti veiksmai gali būti pagrindas blokuoti
                Naudotojo paskyrą. Priklausomai nuo veiksmų, jie gali būti
                įvertinti ir kaip civilinės, administracinės ar baudžiamosios
                teisės pažeidimai ir dėl jų gali būti kreipiamasi į teismą ar
                atitinkamas institucijas.
              </li>
            </ol>
          </li>
          <li>
            Garantijos ir atsakomybės
            <ol>
              <li>
                Bendrovė įsipareigoja teikiant paslaugas Naudotojui veikti
                sąžiningai, teisingai ir profesionaliai.
              </li>
              <li>
                Bendrovė neprisiima atsakomybės dėl KIPOS projektų valdymo
                programos veikimo sutrikimų ir klaidų. Bendrovė taip pat bet
                kuriuo metu gali taisyti, modifikuoti, tobulinti ar atlikti
                kitokius būtinus pakeitimus KIPOS projektų valdymo programoje,
                kurie gali sustabdyti KIPOS projektų valdymo programos veikimą.
                Nepaisant to, Bendrovė įsipareigoja šalinti atsiradusius KIPOS
                projektų valdymo programos veiklos sutrikimus per protingą
                terminą.
              </li>
              <li>
                Bendrovė neužtikrina nepertraukiamos KIPOS projektų valdymo
                programos veiklos, kadangi veiklą gali įtakoti daugybė veiksnių,
                kurių Bendrovė negali įtakoti. Bendrovė dės visas pastangas, kad
                užtikrintų kiek įmanoma sklandesnį KIPOS projektų valdymo
                programos veikimą. Sistemos veiklos sutrikimais nėra laikomi
                atvejai, kai laikinai (ne ilgiau kaip 24 (dvidešimt keturioms)
                valandoms) ribojamas prisijungimas prie KIPOS projektų valdymo
                programos dėl tvarkymo darbų ir kitais atvejais, prieš tai
                informavus Naudotoją.
              </li>
              <li>
                KIPOS projektų valdymo programai taikoma 99,9% metinio
                pasiekiamumo garantija. Jei pasiekiamumas yra mažesnis, per 7
                kalendorines dienas, Bendrovė įsipareigoja kompensuoti 1 dienos
                mokestį už kiekvieną 0,1% pasiekiamumo normos nukrypimą.
              </li>
              <li>
                Bendrovė neatsako už KIPOS projektų valdymo programoje paskelbtą
                informaciją, jos tikslumą ar klaidingumą, ir iš to kilusias
                neigiamas pasekmes Naudotojui ir (ar) tretiesiems asmenimis.
                Naudotojas supranta ir sutinka, kad KIPOS projektų valdymo
                programoje paskelbta informacija jis naudojasi savo nuožiūra ir
                prisiima visą su tuo susijusią riziką.
              </li>
              <li>
                Bendrovė įsipareigoja be Naudotojo sutikimo nenaudoti ir
                neplatinti KIPOS projektų valdymo programoje esančios Naudotojo
                konfidencialios informacijos (tai informacija, kurią Naudotojas
                pateikia, įveda ar kitais būdais įkelia į KIPOS projektų valdymo
                programą). Taip pat neleisti minėtų veiksmų atlikti bet kurioms
                trečiosioms šalims, išskyrus teisės aktų nustatytus atvejus.
              </li>
              <li>
                Bendrovė turi teisę prieiti prie jos duomenų bazėse saugomų
                Naudotojo duomenų konsultuojant Naudotoją arba vykdant
                statistinius tyrimus.
              </li>
              <li>
                Naudotojas yra atsakingas už žalą, kilusią Bendrovei ir (ar)
                tretiesiems asmenims dėl Naudotojo veiksmų netinkamai
                naudojantis KIPOS projektų valdymo programa.
              </li>
            </ol>
          </li>
          <li>
            Ginčų sprendimas
            <ol>
              <li>
                Už netikslios ir vienpusiškos informacijos paviešinimą apie
                Bendrovę ar jos teikiamas paslaugas, dėl kurios gali kilti žala
                dalykinei reputacijai — gresia teisinė atsakomybė.
              </li>
              <li>
                Ginčai tarp Bendrovės ir Naudotojo dėl KIPOS projektų valdymo
                programos naudojimo yra sprendžiami derybų keliu. Ginčo
                neišsprendus derybų keliu, ginčas sprendžiamas įstatymų
                nustatyta tvarka Lietuvos Respublikos teismuose.
              </li>
              <li>
                Visi ginčai yra priskiriami Lietuvos Respublikos teismų
                jurisdikcijai ir sprendžiami vadovaujantis Lietuvos Respublikos
                teise.
              </li>
            </ol>
          </li>
          <li>
            Kontaktinė informacija
            <ol>
              <li>
                Su Bendrovės atstovais galima susisiekti elektroniniu paštu
                info@kipos.lt ar kitais būdais, nurodytais jos internetiniame
                puslapyje www.kipos.lt.
              </li>
            </ol>
          </li>
          <li>
            Atnaujinimai
            <ol>
              <li>
                Bendrovė bet kuriuo metu gali pakeisti Taisykles. Bet kuris
                pakeitimas, išskyrus pakeitimus, susijusius su kainodara,
                pradedamas taikyti iš karto nuo jo paskelbimo ir atnaujinimo. Su
                kainodara susiję pakeitimai pradedami taikyti po 30 dienų nuo
                kainodaros atnaujinimo. KIPOS projektų valdymo programos
                naudojimas po pakeitimo reiškia sutikimą su jų pakeitimu.
              </li>
            </ol>
          </li>
        </ol>
        <p>Paskutinio atnaujinimo data 2025-02-24</p>
      </Section>
      <Footing />
    </>
  );
};

export default TermsOfService;
